var loadingDiv = document.createElement("div")
loadingDiv.id = "loading"
loadingDiv.textContent = "Please wait..."
loadingDiv.style.cssText = "position: absolute; top: 0; left: 0; z-index: 1001; width: 100%; height: 100vh; background-color: #fff; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 10px; color: #666;"

//var logo = new Image()
//logo.src = "/dist/images/logo-small.png"

var anim = new Image()
anim.src = "/dist/images/loading.svg"

//loadingDiv.textContent = ""
//loadingDiv.appendChild(anim)
loadingDiv.insertBefore(anim, loadingDiv.firstChild)

document.body.style.overflow = "hidden"
document.body.insertBefore(loadingDiv, document.body.firstChild)

const assetsLoaded = () => {
    document.body.removeChild(document.getElementById("loading"))
    document.body.style.cssText = ""
}

window.addEventListener('load', assetsLoaded, false)
